export const ROLE_ADMIN = 1;
export const ROLE_RESELLER = 2;
export const ROLE_AGENT = 3;
export const ROLE_ACTOR = 4;
export const ROLE_VIEWER = 5;
export const ROLE_USER = 6;

export const ROLE_ROOT_ADMIN = 'root admin';
export const ROLE_RESELLER_ADMIN = 'reseller admin';

export const IMAGE_VIEW_UNKILLED_SELECT = 0;
export const IMAGE_VIEW_ALL_SELECT = 1;
export const IMAGE_VIEW_ALL_UNKILLED = 2;
export const IMAGE_VIEW_ALL = 3;

export const ALL_UNKILLED_SELECTS_IMAGES = 0;
export const ALL_SELECTS_IMAGES = 1;
export const ALL_UNKILLED_IMAGES = 2;
export const ALL_IMAGES = 3;
