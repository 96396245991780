/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import Helmet from 'react-helmet';
import {
    Table, Row, Col, Icon, Alert, Spin
} from 'antd';
import moment from 'moment';
import _ from 'underscore';
import Menu from '../../components/Menu';
import { ROLE_VIEWER, ALL_IMAGES, ALL_UNKILLED_SELECTS_IMAGES, ALL_SELECTS_IMAGES, ALL_UNKILLED_IMAGES } from '../../core/utils/value';

export default function () {
    const { downloadQueue, params, isFetching, productionUserStats } = this.props;

    const { currentUser } = productionUserStats;
    if (!currentUser) {
        return null;
    }

    const reportNames = [
        'All Images',
        'Un-Killed Images',
        'All Killed Images',
        'All selects images',
        'Un-Killed selects images',
        'Killed selects images',
    ];

    const columns = [{
        title: 'Type',
        dataIndex: 'list_type',
        key: 'list_type',
        render: (list_type) => {
            if (list_type >= 0 && list_type < reportNames.length) {
                return (
                    <span>
                        {reportNames[list_type]}
                        {' '}
                        list
                    </span>
                );
            }
            return <span>(Not-Set)</span>;
        }
    }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
            switch (status) {
            case 0:
                return <span>Pending</span>;
            case 1:
                return <span>Processing</span>;
            case 2:
                return <span>Finished</span>;
            default:
                return <span>(Not-Set)</span>;
            }
        }
    }, {
        title: 'Generated On',
        key: 'generated_date',
        dataIndex: 'generated_date',
        render: (generated_date) => <span>{!_.isNull(generated_date) ? moment(generated_date, 'YYYY-MM-DD HH:ii:ss').format('DD/MM/YYYY HH:mm:ss') : ''}</span>
    }, {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            <span>
                {parseInt(record.status, 10) === 2
                    ? (
                        <span>
                            <a onClick={() => this.downloadFile(record.list_type, record.file, 'pdf', 'download')}>
                                <Icon type="download" />
                                {' '}
                                Download
                            </a>
                        </span>
                    )
                    : null}
            </span>
        ),
    }];

    const renderPDFDownload = (number) => (
        <Row>
            <Col xs={2} />
            <Col xs={18}>{`... ${reportNames[number]} report`}</Col>
            <Col xs={4}>
                <a onClick={() => this.downloadFile(number, 'none', 'pdf', 'generate')}>
                    <Icon type="file-pdf" />
                    {' '}
                    Request
                </a>
            </Col>
        </Row>
    );

    const renderXSLXDownload = (number) => {
        const reportName = reportNames[number].toLowerCase().replace(/\W/g, '-');
        return (
            <Row>
                <Col xs={2} />
                <Col xs={18}>{`... ${reportNames[number]} report`}</Col>
                <Col xs={4}>
                    <a onClick={() => this.downloadFile(number, `${params.production}-${reportName}.xlsx`, 'xlsx', 'download')}>
                        <Icon type="file-excel" />
                        {' '}
                        Request
                    </a>
                </Col>
            </Row>
        );
    };

    const renderImageDownload = (number) => {
        const reportName = reportNames[number].toLowerCase().replace(/\W/g, '-');
        return (
            <Row>
                <Col xs={2} />
                <Col xs={18}>{reportNames[number]}</Col>
                <Col xs={4}>
                    <a onClick={() => this.downloadFile(number, `${params.production}-${reportName}.zip`, 'zip', 'download')}>
                        <Icon type="file-zip" />
                        {' '}
                        Request
                    </a>
                </Col>
            </Row>
        );
    }; 

    const renderPDFDownloadByPermissions = () => {
        if (currentUser.user_type === ROLE_VIEWER && currentUser.SelectImageViewOption !== ALL_IMAGES) {
            if (currentUser.SelectImageViewOption === ALL_UNKILLED_SELECTS_IMAGES) {
                return renderPDFDownload(4);
            } if (currentUser.SelectImageViewOption === ALL_SELECTS_IMAGES) {
                return [
                    renderPDFDownload(3),
                    renderPDFDownload(4),
                    renderPDFDownload(5)
                ];
            } if (currentUser.SelectImageViewOption === ALL_UNKILLED_IMAGES) {
                return [
                    renderPDFDownload(1),
                    renderPDFDownload(4)
                ];
            } if (currentUser.SelectImageViewOption === ALL_IMAGES) {
                return renderPDFDownload(0);
            }
        } else {
            return [
                renderPDFDownload(0),
                renderPDFDownload(2),
                renderPDFDownload(1),
                renderPDFDownload(3),
                renderPDFDownload(5),
                renderPDFDownload(4)
            ];
        }
    };

    const renderXSLXDownloadByPermissions = () => {
        if (currentUser.user_type === ROLE_VIEWER && currentUser.SelectImageViewOption !== ALL_IMAGES) {
            if (currentUser.SelectImageViewOption === ALL_UNKILLED_SELECTS_IMAGES) {
                return renderXSLXDownload(4);
            } if (currentUser.SelectImageViewOption === ALL_SELECTS_IMAGES) {
                return [
                    renderXSLXDownload(3),
                    renderXSLXDownload(4),
                    renderXSLXDownload(5)
                ];
            } if (currentUser.SelectImageViewOption === ALL_UNKILLED_IMAGES) {
                return [
                    renderXSLXDownload(1),
                    renderXSLXDownload(4),
                ];
            } if (currentUser.SelectImageViewOption === ALL_IMAGES) {
                return renderXSLXDownload(0);
            }
        } else {
            return [
                renderXSLXDownload(0),
                renderXSLXDownload(2),
                renderXSLXDownload(1),
                renderXSLXDownload(3),
                renderXSLXDownload(5),
                renderXSLXDownload(4)
            ];
        }
    };

    const renderImageDownloadByPermissions = () => {
        if (currentUser.user_type === ROLE_VIEWER) {
            if (currentUser.SelectImageViewOption === ALL_UNKILLED_SELECTS_IMAGES) {
                return renderImageDownload(4);
            } if (currentUser.SelectImageViewOption === ALL_SELECTS_IMAGES) {
                return renderImageDownload(4);
            } if (currentUser.SelectImageViewOption === ALL_UNKILLED_IMAGES) {
                return [
                    renderImageDownload(1),
                    renderImageDownload(4)
                ];
            } if (currentUser.SelectImageViewOption === ALL_IMAGES) {
                return renderImageDownload(0);
            }
        } else {
            return [
                renderImageDownload(0),
                renderImageDownload(1),
                renderImageDownload(4),
                renderImageDownload(2)
            ];
        }
    };

    return (
        <div className="page-wrap selectday">
            <Helmet title="Select a day" />
            <div className="header">
                <div className="container">
                    <div className="header__inner">
                        <Row type="flex" justify="space-between" align="middle">
                            <Col span={16}>
                                <div className="flex-center-stretch">
                                    <Menu />
                                    Download PDF
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="container downloads-list">
                <Spin spinning={isFetching}>
                    <Row>
                        <Col xs={24}><h2>Request a new report</h2></Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={24}><h3>PDF Reports</h3></Col>
                    </Row>

                    {renderPDFDownloadByPermissions()}

                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={24}><h3>XSLX Reports</h3></Col>
                    </Row>

                    {renderXSLXDownloadByPermissions()}

                    {currentUser.AllowImageDownload !== 0 && (
                        <div>
                            <Row style={{ marginBlock: '20px' }}>
                                <Col xs={24}>
                                    <h3>Image Downloads - JPGs (Low Res)</h3>
                                </Col>
                                <Col xs={24}>
                                    Please note that unkilled does not mean approved. Images are only considered approved when all actors 
                                    have marked that day/folder of images as done.
                                    <br />
                                    <br />
                                    Please visit the View Images page to check if any day/folder of images is waiting for the cast to lock 
                                    in their kills. You can also see lists of which images have been killed and which days/folders of images 
                                    have been completed by all actors in the downloadable reports above.
                                </Col>
                            </Row>    

                            {renderImageDownloadByPermissions()}
                        </div>
                    )}


                    <Row style={{ marginTop: '40px' }}>
                        <Col xs={24}>
                            <Alert message="Generated pdf file(s) will be automatically deleted after 24 hours." type="info" />
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={24}>
                            <Table
                                pagination={false}
                                columns={columns}
                                dataSource={downloadQueue} />
                        </Col>
                    </Row>
                </Spin>
            </div>
        </div>
    );
}
