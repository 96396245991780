/* eslint-disable no-mixed-operators */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import {
    Col, Row, Progress, Divider
} from 'antd';
import { map, sortBy } from 'underscore';

function ModalContent(props) {
    const {
        type,
        currentUser,
        userSoloTotalImages,
        userGroupTotalImages,
        userLowerGroupTotalImages,
        productionTotalImages,
        killedImagesLink,
        filteredUserStats,
        userUpperGroupTotalImages,
        approveSelectedImages,
        totalUnkilledImages,
    } = props;

    let sortedUserStats = sortBy(filteredUserStats, 'name').reverse();
    sortedUserStats = sortBy(sortedUserStats, 'userType').reverse();

    const chartGreenColor = '#52c41a';
    const chartBlueColor = '#1890ff';
    const chartRedColor = '#c00000';

    let upperUsers = 0;
    switch (type) {
    case 'user': {
        const totalAllowedSoloKillImages = Math.ceil((currentUser.soloKillLimit / 100) * userSoloTotalImages);
        const remainingAllowedSoloKillImages = totalAllowedSoloKillImages - currentUser.soloKills;
        const soloKillAllowanceUsedPercentage = Math.ceil(currentUser.soloKills / Math.ceil((currentUser.soloKillLimit / 100) * userSoloTotalImages) * 100);
        const soloKillAllowanceRemainingPercentage = 100 - soloKillAllowanceUsedPercentage;

        let soloColor = chartGreenColor;
        if (soloKillAllowanceUsedPercentage >= 30 && soloKillAllowanceUsedPercentage < 70) {
            soloColor = chartBlueColor;
        } else if (soloKillAllowanceUsedPercentage >= 70) {
            soloColor = chartRedColor;
        }

        const totalAllowedGroupKillImages = Math.ceil((currentUser.groupKillLimit / 100) * userGroupTotalImages);
        const remainingAllowedGroupKillImages = totalAllowedGroupKillImages - currentUser.groupKills;
        const groupKillAllowanceUsedPercentage = Math.ceil(currentUser.groupKills / Math.ceil((currentUser.groupKillLimit / 100) * userGroupTotalImages) * 100);
        const groupKillAllowanceRemainingPercentage = 100 - groupKillAllowanceUsedPercentage;

        let groupColor = chartGreenColor;
        if (groupKillAllowanceUsedPercentage >= 30 && groupKillAllowanceUsedPercentage < 70) {
            groupColor = chartBlueColor;
        } else if (groupKillAllowanceUsedPercentage >= 70) {
            groupColor = chartRedColor;
        }

        return (
            <div style={{ textAlign: 'center' }}>
                <Row>
                    <Col sm={12} xs={24}>
                        <h2>Solo</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={soloColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={soloKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedSoloKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.soloKills}
                                            /
                                            {totalAllowedSoloKillImages}
                                            {' '}
                                            (
                                            {soloKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>

                    </Col>
                    <Col sm={12} xs={24}>
                        <h2>Group</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={groupColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={groupKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedGroupKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.groupKills}
                                            /
                                            {totalAllowedGroupKillImages}
                                            {' '}
                                            (
                                            {groupKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col xs={24}><h2>Summary</h2></Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Solo:</strong>
                            {' '}
                            You have used
                            <strong>{currentUser.soloKills}</strong>
                            {' '}
                            (
                            {soloKillAllowanceUsedPercentage}
                            %) of your available solo kill allowance of
                            <strong>{totalAllowedSoloKillImages}</strong>
                            {' '}
                            images, meaning you have
                            <strong>{remainingAllowedSoloKillImages}</strong>
                            {' '}
                            images (
                            {soloKillAllowanceRemainingPercentage}
                            %) remaining that you can kill within your solo kill allowance.
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Group:</strong>
                            {' '}
                            You have used
                            <strong>{currentUser.groupKills}</strong>
                            {' '}
                            (
                            {groupKillAllowanceUsedPercentage}
                            %) of your available group kill allowance of
                            <strong>{totalAllowedGroupKillImages}</strong>
                            {' '}
                            images, meaning you have
                            <strong>{remainingAllowedGroupKillImages}</strong>
                            {' '}
                            images (
                            {groupKillAllowanceRemainingPercentage}
                            %) remaining that you can kill within your group kill allowance.
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            You appear in a total of
                            {' '}
                            <strong>{currentUser.userTotalImages}</strong>
                            {' '}
                            images across this production.
                            {' '}
                            <strong>{currentUser.userSoloImages}</strong>
                            {' '}
                            of these
                            images are solo images, and
                            {' '}
                            <strong>{currentUser.userGroupImages}</strong>
                            {' '}
                            are group images. You may kill up to
                            {' '}
                            <strong>{totalAllowedSoloKillImages}</strong>
                            {' '}
                            solo
                            images, and
                            {' '}
                            <strong>{totalAllowedGroupKillImages}</strong>
                            {' '}
                            group images in total.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <Link className="ant-btn regular-button" to={killedImagesLink}>View all killed images</Link>
                    </Col>
                </Row>
            </div>
        );
    }
    case 'upper': {
        const totalAllowedSoloKillImages = Math.ceil((currentUser.soloKillLimit / 100) * userSoloTotalImages);
        const remainingAllowedSoloKillImages = totalAllowedSoloKillImages - currentUser.soloKills;
        const soloKillAllowanceUsedPercentage = Math.ceil(currentUser.soloKills / Math.ceil((currentUser.soloKillLimit / 100) * userSoloTotalImages) * 100);
        const soloKillAllowanceRemainingPercentage = 100 - soloKillAllowanceUsedPercentage;

        let soloColor = chartGreenColor;
        if (soloKillAllowanceUsedPercentage >= 30 && soloKillAllowanceUsedPercentage < 70) {
            soloColor = chartBlueColor;
        } else if (soloKillAllowanceUsedPercentage >= 70) {
            soloColor = chartRedColor;
        }

        const totalAllowedGroupKillImages = Math.ceil((currentUser.groupKillLimit / 100) * userLowerGroupTotalImages);
        const remainingAllowedGroupKillImages = totalAllowedGroupKillImages - currentUser.groupKills;
        const groupKillAllowanceUsedPercentage = Math.ceil(currentUser.groupKills / Math.ceil((currentUser.groupKillLimit / 100) * userLowerGroupTotalImages) * 100);
        const groupKillAllowanceRemainingPercentage = 100 - groupKillAllowanceUsedPercentage;

        let groupColor = chartGreenColor;
        if (groupKillAllowanceUsedPercentage >= 30 && groupKillAllowanceUsedPercentage < 70) {
            groupColor = chartBlueColor;
        } else if (groupKillAllowanceUsedPercentage >= 70) {
            groupColor = chartRedColor;
        }

        const totalAllowedUpperKillImages = Math.ceil((currentUser.upperGroupKillLimit / 100) * userUpperGroupTotalImages);
        const remainingAllowedUpperKillImages = totalAllowedUpperKillImages - currentUser.upperGroupKills;
        const upperKillAllowanceUsedPercentage = Math.ceil(currentUser.upperGroupKills / Math.ceil((currentUser.upperGroupKillLimit / 100) * userUpperGroupTotalImages) * 100);
        const upperKillAllowanceRemainingPercentage = 100 - upperKillAllowanceUsedPercentage;

        let upperColor = chartGreenColor;
        if (upperKillAllowanceUsedPercentage >= 30 && upperKillAllowanceUsedPercentage < 70) {
            upperColor = chartBlueColor;
        } else if (upperKillAllowanceUsedPercentage >= 70) {
            upperColor = chartRedColor;
        }

        return (
            <div style={{ textAlign: 'center' }}>
                <Row>
                    <Col sm={12} xs={24}>
                        <h2>Solo</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={soloColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={soloKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedSoloKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.soloKills}
                                            /
                                            {totalAllowedSoloKillImages}
                                            {' '}
                                            (
                                            {soloKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>

                    </Col>
                    <Col sm={12} xs={24}>
                        <h2>Lower Group</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={groupColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={groupKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedGroupKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.groupKills}
                                            /
                                            {totalAllowedGroupKillImages}
                                            {' '}
                                            (
                                            {groupKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>
                    </Col>
                    <Col xs={24} style={{ textAlign: 'center' }}>
                        <h2>Upper Group</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={upperColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={upperKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedUpperKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.upperGroupKills}
                                            /
                                            {totalAllowedUpperKillImages}
                                            {' '}
                                            (
                                            {upperKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col xs={24}><h2>Summary</h2></Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Solo:</strong>
                            {' '}
                            You have used
                            <strong>{currentUser.soloKills}</strong>
                            {' '}
                            (
                            {soloKillAllowanceUsedPercentage}
                            %) of your available solo kill allowance of
                            <strong>{totalAllowedSoloKillImages}</strong>
                            {' '}
                            images, meaning you have
                            <strong>{remainingAllowedSoloKillImages}</strong>
                            {' '}
                            images (
                            {soloKillAllowanceRemainingPercentage}
                            %) remaining that you can kill within your solo kill allowance.
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Lower Group:</strong>
                            {' '}
                            You have used
                            <strong>{currentUser.groupKills}</strong>
                            {' '}
                            (
                            {groupKillAllowanceUsedPercentage}
                            %) of your available lower group kill allowance of
                            <strong>{totalAllowedGroupKillImages}</strong>
                            {' '}
                            images, meaning you have
                            <strong>{remainingAllowedGroupKillImages}</strong>
                            {' '}
                            images (
                            {groupKillAllowanceRemainingPercentage}
                            %) remaining that you can kill within your lower group kill allowance.
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Upper Group:</strong>
                            {' '}
                            You have used
                            <strong>{currentUser.upperGroupKills}</strong>
                            {' '}
                            (
                            {upperKillAllowanceUsedPercentage}
                            %) of your available upper group kill allowance of
                            <strong>{totalAllowedUpperKillImages}</strong>
                            {' '}
                            images, meaning you have
                            <strong>{remainingAllowedUpperKillImages}</strong>
                            {' '}
                            images (
                            {upperKillAllowanceRemainingPercentage}
                            %) remaining that you can kill within your upper group kill allowance.
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            You appear in a total of
                            {' '}
                            <strong>{productionTotalImages}</strong>
                            {' '}
                            images across this production.
                            {' '}
                            <strong>{userSoloTotalImages}</strong>
                            {' '}
                            of these
                            images are solo images,
                            {' '}
                            <strong>{userLowerGroupTotalImages}</strong>
                            {' '}
                            are lower group images, and
                            {' '}
                            <strong>{userUpperGroupTotalImages}</strong>
                            {' '}
                            are upper group images. You may kill up to
                            {' '}
                            <strong>{totalAllowedSoloKillImages}</strong>
                            {' '}
                            solo
                            images, up to
                            {' '}
                            <strong>{totalAllowedGroupKillImages}</strong>
                            {' '}
                            lower group images, and
                            {' '}
                            <strong>{totalAllowedUpperKillImages}</strong>
                            {' '}
                            upper group images in total.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <Link className="ant-btn regular-button" to={killedImagesLink}>View all killed images</Link>
                    </Col>
                </Row>
            </div>
        );
    }

    case 'agent': {
        return (
            <div className="kill-allowance-widget-inner-div" style={{ textAlign: 'center' }}>
                <Row>
                    <Col xs={24}>
                        <p>
                            Total image count:
                            {' '}
                            {productionTotalImages}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <p>
                            Total approved selects count:
                            {' '}
                            {approveSelectedImages}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <p>
                            Total Unkilled:
                            {' '}
                            {totalUnkilledImages}
                        </p>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 15 }}>
                    <Col xs={24}>
                        <h4>Kill Allowance mechanism</h4>
                        <h2>Solo & Group</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3} />
                    <Col xs={9}><h3>Total images</h3></Col>
                    <Col xs={6}><h3>Kill allowance</h3></Col>
                    <Col xs={6}><h3>UnKilled</h3></Col>
                </Row>
                <Row>
                    <Col xs={3} />
                    <Col xs={3} className="kill-allowance-widget-header">Total</Col>
                    <Col xs={3} className="kill-allowance-widget-header">Solo</Col>
                    <Col xs={3} className="kill-allowance-widget-header">Group</Col>
                    <Col xs={3} className="kill-allowance-widget-header">Solo</Col>
                    <Col xs={3} className="kill-allowance-widget-header">Group</Col>
                    <Col xs={3} className="kill-allowance-widget-header">Solo</Col>
                    <Col xs={3} className="kill-allowance-widget-header">Group</Col>
                </Row>
                {map(sortedUserStats, (userStat, userStatIndex) => (userStat.allowanceType === 1 ? (
                    <Row key={userStatIndex} style={{ marginTop: 10 }}>
                        <Col xs={3} style={{ textAlign: 'left', fontWeight: 'bold' }}>{userStat.name}</Col>

                        { /* Total Images */}
                        <Col
                            xs={3}>
                            {userStat.userSoloImages + userStat.userGroupImages /* total images the user has been tagged in */}
                        </Col>

                        { /* Total Solo */}
                        <Col
                            xs={3}>
                            {userStat.userSoloImages}
                        </Col>

                        { /* Total Solo */}
                        <Col
                            xs={3}>
                            {userStat.userGroupImages}
                        </Col>

                        { /* Solo */
                            userStat.soloKillLimit === 0
                                ? <Col xs={3}>Unlimited</Col>
                                : (
                                    <Col
                                        xs={3}>
                                        {userStat.soloKilled}
                                        {' '}
                                        of
                                        {' '}
                                        {Math.ceil((userStat.soloKillLimit / 100) * userStat.userSoloImages)}
                                        {' '}
                                        (
                                        {Math.ceil(userStat.soloKilled / ((userStat.soloKillLimit === 0 ? 1 : Math.ceil((userStat.soloKillLimit / 100) * userStat.userSoloImages)) || 1) * 100)}
                                        %)
                                    </Col>
                                )
                        }

                        { /* Group */
                            userStat.groupKillLimit === 0
                                ? <Col xs={3}>Unlimited</Col>
                                : (
                                    <Col
                                        xs={3}>
                                        {userStat.groupKilled}
                                        {' '}
                                        of
                                        {' '}
                                        {Math.ceil((userStat.groupKillLimit / 100) * userStat.userGroupImages)}
                                        {' '}
                                        (
                                        {Math.ceil(userStat.groupKilled / ((userStat.groupKillLimit === 0 ? 1 : Math.ceil((userStat.groupKillLimit / 100) * userStat.userGroupImages)) || 1) * 100)}
                                        %)
                                    </Col>
                                )
                        }

                        {
                            /* Solo */
                            userStat.userSoloImages + userStat.userGroupImages === 0
                                ? <Col xs={3}>0</Col>
                                : (
                                    <Col
                                        xs={3}>
                                        {userStat.soloUnkilled}
                                    </Col>
                                )
                        }

                        {
                            /* Group */
                            userStat.userSoloImages + userStat.userGroupImages === 0
                                ? <Col xs={3}>0</Col>
                                : (
                                    <Col
                                        xs={3}>
                                        {userStat.groupUnkilled}
                                    </Col>
                                )
                        }

                    </Row>
                ) : null))}

                <Row style={{ marginTop: 30, marginBottom: 15 }}>
                    <Col xs={24}>
                        <h4>Kill Allowance mechanism</h4>
                        <h2>
                            Solo, lower group,
                            <br />
                            {' '}
                            upper group
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={7} />
                    <Col xs={9}><h3>Total images</h3></Col>
                    <Col xs={6}><h3>Kill allowance</h3></Col>
                </Row>
                <Row>
                    <Col xs={7} />
                    <Col xs={2} className="kill-allowance-widget-header">Total</Col>
                    <Col xs={2} className="kill-allowance-widget-header">Solo</Col>
                    <Col xs={2} className="kill-allowance-widget-header">Group</Col>
                    <Col xs={2} className="kill-allowance-widget-header">Group Upper</Col>
                    <Col xs={3} className="kill-allowance-widget-header">Solo</Col>
                    <Col xs={3} className="kill-allowance-widget-header">Group</Col>
                    <Col xs={3} className="kill-allowance-widget-header">Group Upper</Col>
                </Row>
                {map(sortedUserStats, (userStat, userStatIndex) => {
                    if (userStat.allowanceType !== 2) {
                        return null;
                    }

                    upperUsers += 1;
                    return (
                        <Row key={userStatIndex} style={{ marginTop: 10 }}>
                            <Col xs={7} style={{ textAlign: 'left', fontWeight: 'bold' }}>{userStat.name}</Col>

                            { /* Total Images */}
                            <Col
                                xs={2}>
                                {userStat.userSoloImages + userStat.userGroupImages /* total images the user has been tagged in */}
                            </Col>

                            { /* Total Solo */}
                            <Col
                                xs={2}>
                                {userStat.userSoloImages}
                            </Col>

                            { /* Total Solo */}
                            <Col
                                xs={2}>
                                {userStat.userGroupImages}
                            </Col>

                            <Col
                                xs={2}>
                                {userStat.userUpperGroupImages}
                            </Col>

                            { /* Solo */
                                userStat.soloKillLimit === 0
                                    ? <Col xs={3}>Unlimited</Col>
                                    : (
                                        <Col
                                            xs={3}>
                                            {userStat.soloKilled}
                                            {' '}
                                            of
                                            {' '}
                                            {Math.ceil((userStat.soloKillLimit / 100) * userStat.userSoloImages)}
                                            {' '}
                                            (
                                            {Math.ceil(userStat.soloKilled / ((userStat.soloKillLimit === 0 ? 1 : Math.ceil((userStat.soloKillLimit / 100) * userStat.userSoloImages)) || 1) * 100)}
                                            %)
                                        </Col>
                                    )
                            }

                            { /* Group */
                                userStat.groupKillLimit === 0
                                    ? <Col xs={3}>Unlimited</Col>
                                    : (
                                        <Col
                                            xs={3}>
                                            {userStat.groupKilled}
                                            {' '}
                                            of
                                            {' '}
                                            {Math.ceil((userStat.groupKillLimit / 100) * userStat.userGroupImages)}
                                            {' '}
                                            (
                                            {Math.ceil(userStat.groupKilled / ((userStat.groupKillLimit === 0 ? 1 : Math.ceil((userStat.groupKillLimit / 100) * userStat.userGroupImages)) || 1) * 100)}
                                            %)
                                        </Col>
                                    )
                            }

                            { /* Group */
                                userStat.upperGroupKillLimit === 0
                                    ? <Col xs={3}>Unlimited</Col>
                                    : (
                                        <Col
                                            xs={3}>
                                            {userStat.upperGroupKilled}
                                            {' '}
                                            of
                                            {' '}
                                            {Math.ceil((userStat.upperGroupKillLimit / 100) * userStat.userUpperGroupImages)}
                                            {' '}
                                            (
                                            {Math.ceil(userStat.upperGroupKilled / ((userStat.upperGroupKillLimit === 0 ? 1 : Math.ceil((userStat.upperGroupKillLimit / 100) * userStat.userUpperGroupImages)) || 1) * 100)}
                                            %)
                                        </Col>
                                    )
                            }

                        </Row>
                    );
                })}

                {upperUsers === 0
                    ? (
                        <Row>
                            <Col xs={24} style={{ textAlign: 'left' }}>There are no users...</Col>
                        </Row>
                    )
                    : false}
                <Row style={{ marginTop: '20px' }}>
                    <Col xs={24}>
                        <Link to={killedImagesLink}>View all killed images</Link>
                    </Col>
                </Row>
            </div>
        );
    }
    case 'viewer': {
        return (
            <div className="kill-allowance-widget-inner-div" style={{ textAlign: 'center' }}>
                <Row>
                    <Col xs={24}>
                        <p>
                            Total image count:
                            {' '}
                            {productionTotalImages}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={8} />
                    <Col xs={3} className="kill-allowance-widget-header">Total</Col>
                </Row>
                {map(sortedUserStats, (userStat, userStatIndex) => (userStat.allowanceType === 1 ? (
                    <Row key={userStatIndex} style={{ marginTop: 10 }}>
                        <Col xs={8} style={{ textAlign: 'left', fontWeight: 'bold' }}>{userStat.name}</Col>

                        { /* Total Images */}
                        <Col
                            xs={3}>
                            {userStat.userSoloImages + userStat.userGroupImages /* total images the user has been tagged in */}
                        </Col>
                    </Row>
                ) : null))}
            </div>
        );
    }
    default:
        return null;
    }
}

ModalContent.defaultProps = {
    killedImagesLink: null,
};

ModalContent.propTypes = {
    type: PropTypes.string.isRequired,
    currentUser: PropTypes.any.isRequired,
    userSoloTotalImages: PropTypes.any.isRequired,
    userGroupTotalImages: PropTypes.any.isRequired,
    productionTotalImages: PropTypes.any.isRequired,
    killedImagesLink: PropTypes.any,
    filteredUserStats: PropTypes.any.isRequired,
    userUpperGroupTotalImages: PropTypes.any.isRequired,
    userLowerGroupTotalImages: PropTypes.any.isRequired,
};

export default ModalContent;
